export const APP_NAME = "Button";

const IS_PROD = true;
export const NETWORK = IS_PROD ? "mainnet" : "hangzhounet";
export const NODE_URL = IS_PROD
  ? "https://mainnet.api.tez.ie"
  : "https://hangzhounet.smartpy.io";
export const BUTTON_CONTRACT = IS_PROD
  ? "KT1JxXpQoeU5PbmdYGCPDpHEKAi4FGKDZZMN"
  : "KT1WinxgAFpCTnP8hBgcSwomnGT3AkUAajfC";

export const NEXT_GAME = 0.05;
export const WINNER_PERCENT = 0.85;
