import { AccountInfo } from "@airgap/beacon-sdk";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

type WalletState = { value: null | AccountInfo };

const initialState = { value: null } as WalletState;

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<WalletState["value"]>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    },
    clear: (state) => {
      state.value = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set, clear } = walletSlice.actions;

export const selectAddress = (state: RootState) => state.wallet.value?.address;

export default walletSlice.reducer;
