import { AccountInfo } from "@airgap/beacon-sdk";
import { Typography } from "@mui/material";
import { BigNumber } from "bignumber.js";
import React, { useCallback, useEffect, useState } from "react";
import {
  connect,
  disconnect,
  getBalance,
  getCurrentAccount,
} from "../../dapp/tezos-service";
import { useAppDispatch } from "../../redux/hooks";
import { clear, set } from "../../redux/walletSlice";
import { WalletButton } from "../Buttons";

export function WalletConnection() {
  const [account, setAccount] = useState<AccountInfo>();
  const [balance, setBalance] = useState<BigNumber>();
  const dispatch = useAppDispatch();

  const accountPkhPreview = (accountPkh?: string) => {
    if (!accountPkh) return undefined;
    else {
      const accPkh = accountPkh;
      const ln = accPkh.length;
      return `${accPkh.slice(0, 7)}...${accPkh.slice(ln - 4, ln)}`;
    }
  };

  const handleConnect = async () => {
    const { account: newAccount, balance: newBalance } = await connect();
    setAccount(newAccount);
    setBalance(newBalance);
    dispatch(set(newAccount || null));
  };

  const handleDisconnect = async () => {
    await disconnect();
    dispatch(clear());
    setAccount(undefined);
    setBalance(new BigNumber(0));
  };

  const initialize = useCallback(async () => {
    const currAccount = await getCurrentAccount();
    if (currAccount) {
      const currBalance = await getBalance(currAccount);
      setBalance(currBalance);
    }
    dispatch(set(currAccount || null));
    setAccount(currAccount);
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        maxWidth: 250,
        alignItems: "center",
      }}
    >
      <Typography variant="body1">
        {balance?.shiftedBy(-6).toNumber() || 0} ꜩ
      </Typography>

      <WalletButton
        variant="contained"
        onClick={async () => {
          if (account) {
            handleDisconnect();
          } else {
            await handleConnect();
          }
        }}
      >
        {account ? accountPkhPreview(account?.address) : "Connect"}
      </WalletButton>
    </div>
  );
}
