import { AccountInfo, NetworkType } from "@airgap/beacon-sdk";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { OpKind, TezosToolkit } from "@taquito/taquito";
import { APP_NAME, BUTTON_CONTRACT, NODE_URL } from "./defaults";
import { BigNumber } from "bignumber.js";

const NETWORK: NetworkType = NetworkType.MAINNET;

const Tezos = new TezosToolkit(NODE_URL);

let globalWallet: BeaconWallet | undefined;

export const getBeaconInstance = async () => {
  if (!globalWallet) {
    // Create a new BeaconWallet instance. The options will be passed to the DAppClient constructor.
    const wallet = new BeaconWallet({
      name: APP_NAME,
      // @ts-ignore
      preferredNetwork: NETWORK,
      eventHandlers: {
        PERMISSION_REQUEST_SUCCESS: {
          handler: async (data) => {
            console.log("permission data:", data);
          },
        },
      },
    });

    // Setting the wallet as the wallet provider for Taquito.
    Tezos.setWalletProvider(wallet);
    globalWallet = wallet;
  }

  return globalWallet;
};

export const connect = async (): Promise<{
  account?: AccountInfo;
  balance?: BigNumber;
  wallet?: BeaconWallet;
}> => {
  const wallet = await getBeaconInstance();

  // If already logged in, return that instance
  const possibleAccount = await wallet.client.getActiveAccount();
  if (possibleAccount) {
    const balance = await getBalance(possibleAccount);

    return { account: possibleAccount, balance, wallet };
  }

  // Handles the permission request
  await wallet.requestPermissions({
    network: { type: NETWORK },
  });

  const account = await wallet.client.getActiveAccount();
  const balance = await getBalance(account);

  return { account, balance };
};

export const disconnect = async () => {
  const wallet = await getBeaconInstance();
  await wallet.clearActiveAccount();
};

export const getCurrentAccount = async () => {
  const wallet = await getBeaconInstance();

  return await wallet.client.getActiveAccount();
};

export const getBalance = async (account?: AccountInfo) => {
  try {
    const userAddress =
      account?.address || (await getCurrentAccount())?.address;
    if (userAddress) {
      const balance = await Tezos.tz.getBalance(userAddress);

      return balance;
    }
  } catch (e) {
    console.error("Error fetching balance: ", e);
  }

  return new BigNumber(0);
};

export type ContractState = {
  admin1: string;
  admin2: string;
  countdown_milliseconds: number;
  countdown_milliseconds_start: number;
  countdown_increase_milliseconds: number;
  start_bid_amount: number;
  next_bid_amount: number;
  bid_increase_percentage: number;
  enabled: boolean;
  leader: string;
  session_start_timestamp: string;
};

export const getContractState = async (): Promise<ContractState> => {
  const contract = await Tezos.contract.at(BUTTON_CONTRACT);
  const contractStorage: Omit<
    ContractState,
    | "countdown_milliseconds"
    | "bid_increase_percentage"
    | "countdown_increase_milliseconds"
    | "countdown_milliseconds_start"
  > & {
    countdown_milliseconds: BigNumber;
    bid_increase_percentage: BigNumber;
    countdown_increase_milliseconds: BigNumber;
    countdown_milliseconds_start: BigNumber;
    next_bid_amount: BigNumber;
    start_bid_amount: BigNumber;
  } = await contract.storage();

  // console.log(contractStorage);
  return {
    ...contractStorage,
    countdown_milliseconds: contractStorage.countdown_milliseconds.toNumber(),
    countdown_increase_milliseconds:
      contractStorage.countdown_increase_milliseconds.toNumber(),
    bid_increase_percentage: contractStorage.bid_increase_percentage.toNumber(),
    countdown_milliseconds_start:
      contractStorage.countdown_milliseconds_start.toNumber(),
    next_bid_amount: contractStorage.next_bid_amount.toNumber(),
    start_bid_amount: contractStorage.start_bid_amount.toNumber(),
  };
};

export const getContractBalance = async (): Promise<string> => {
  return (await Tezos.tz.getBalance(BUTTON_CONTRACT)).shiftedBy(-6).toString();
};

let lastUpdatedBlockHash: string = "";

export const checkRecentBlockForUpdates = async () => {
  const block = await Tezos.rpc.getBlock();

  const newRelevantBlock =
    block.hash !== lastUpdatedBlockHash &&
    block.operations[3].some((ops) =>
      ops.contents.some(
        (op) =>
          op.kind === OpKind.TRANSACTION && op.destination === BUTTON_CONTRACT
      )
    );

  lastUpdatedBlockHash = block.hash;

  return newRelevantBlock;
};

export const withdrawBalance = async () => {
  const contract = await Tezos.wallet.at(BUTTON_CONTRACT);
  const result = await contract.methods.withdraw("").send();
  return result;
};
