import { createTheme, ThemeProvider, Typography } from "@mui/material";
import moment from "moment";
import numbro from "numbro";
import React, { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { Provider } from "react-redux";
import "./App.css";
import BidButton from "./components/BidButton";
import Header from "./components/Header";
import WithdrawButton from "./components/WithdrawButton";
import { NEXT_GAME, WINNER_PERCENT } from "./dapp/defaults";
import {
  checkRecentBlockForUpdates,
  ContractState,
  getContractBalance,
  getContractState,
} from "./dapp/tezos-service";
import {
  selectContractBalance,
  selectContractState,
  setContractBalance,
  setContractState,
} from "./redux/contractSlice";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { store } from "./redux/store";
import { selectAddress } from "./redux/walletSlice";

const theme = createTheme({
  typography: {
    allVariants: {
      color: "white",
    },
  },
});

const WinnerAnnouncement = ({
  contractState,
}: {
  contractState?: ContractState;
}) => {
  // if ()

  return (
    <Typography variant="h4" component="div">
      The winner is...
    </Typography>
  );
};

const Body = () => {
  const [endTime, setEndTime] = useState<Date>();

  const dispatch = useAppDispatch();
  const address = useAppSelector(selectAddress);
  const contractState = useAppSelector(selectContractState);
  const contractBalance = useAppSelector(selectContractBalance);

  const readContractStateAndBalance = async () => {
    const state = await getContractState();
    dispatch(setContractState(state));
    const balance = await getContractBalance();
    dispatch(setContractBalance(balance));

    if (state?.session_start_timestamp && state?.countdown_milliseconds) {
      const newEndTime = moment(state.session_start_timestamp)
        .add(state.countdown_milliseconds, "milliseconds")
        .toDate();
      setEndTime(newEndTime);
    } else {
    }
  };

  useEffect(() => {
    readContractStateAndBalance();
  }, []);

  const intervalRef = useRef<undefined | NodeJS.Timeout>();

  useEffect(() => {
    intervalRef.current = setInterval(async () => {
      const hasUpdates = await checkRecentBlockForUpdates();
      if (hasUpdates) {
        console.log("recent block has changes, refetching contract state...");
        readContractStateAndBalance();
      }
    }, 10 * 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const gameFinished =
    !!contractState?.session_start_timestamp &&
    !!contractState?.countdown_milliseconds &&
    moment(endTime).isBefore(moment());
  // Game end is in the future

  const gameRunning =
    contractState?.leader !== null &&
    contractState?.session_start_timestamp !== null &&
    !gameFinished;

  /* 
    cases to handle:
      - first game has not started
      - game has finished, paid out, new game has not been started

      - game is in session
      
      - game has finished but not been paid out
  */

  const winner_amount = +(contractBalance || 0) * WINNER_PERCENT;
  const next_round_amount = +(contractBalance || 0) * NEXT_GAME;

  const loading = !contractBalance || !contractState;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
      }}
    >
      {!loading ? (
        <>
          {gameRunning && !!endTime ? (
            <>
              <Typography paddingTop={3} variant="h1" component="div">
                <Countdown date={endTime} daysInHours zeroPadTime={2}>
                  <WinnerAnnouncement contractState={contractState} />
                </Countdown>
              </Typography>
              <Typography variant="body1" paddingBottom={3}>
                {gameRunning ? "Current leader" : "Winner"}:{" "}
                {contractState?.leader}
              </Typography>
            </>
          ) : (
            <Typography
              variant="body1"
              component="div"
              style={{ paddingBottom: 32 }}
            >
              {contractState?.leader
                ? `Round over! The winner is ${contractState.leader}`
                : ""}
            </Typography>
          )}
          <Typography variant="h3" component="div">
            {contractBalance
              ? numbro(contractBalance).format({ thousandSeparated: true })
              : "--"}{" "}
            ꜩ
          </Typography>
          <Typography variant="caption" component="div" paddingBottom={3}>
            Current Pool Value
          </Typography>
          <BidButton gameRunning={gameRunning} />
          <WithdrawButton gameRunning={gameRunning} />
          {contractBalance ? (
            <div
              style={{
                paddingTop: 24,
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                maxWidth: 400,
              }}
            >
              <Typography variant="body1" paddingBottom={2}>
                Click the button to become the new leader and increase the
                counter by 1 minute. When the timer runs out the last person who
                interacted with the button receives 85% of the game pool.
              </Typography>
              <Typography variant="caption">
                Winner receives 85% of the pool (
                {numbro(winner_amount).format({
                  thousandSeparated: true,
                  mantissa: 6,
                })}
                ) ꜩ
              </Typography>
              <Typography variant="caption">
                Next round will start with 5% of the pool (
                {numbro(next_round_amount).format({
                  thousandSeparated: true,
                  mantissa: 6,
                })}
                ) ꜩ
              </Typography>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

const AppInner = () => (
  <div className="App">
    <Header />
    <Body />
  </div>
);

function App() {
  return (
    <React.Suspense fallback={null}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppInner />
        </ThemeProvider>
      </Provider>
    </React.Suspense>
  );
}

export default App;
