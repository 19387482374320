import { BigNumber } from "bignumber.js";
import React from "react";
import { useSelector } from "react-redux";
import { BUTTON_CONTRACT } from "../dapp/defaults";
import { getBeaconInstance } from "../dapp/tezos-service";
import { selectContractState } from "../redux/contractSlice";
import { BidButtonInner } from "./Buttons";

const BidButton = ({ gameRunning }: { gameRunning: boolean }) => {
  const contractState = useSelector(selectContractState);
  const bidAmount = gameRunning
    ? contractState?.next_bid_amount
    : contractState?.start_bid_amount;
  const handleBid = async () => {
    // If user is not connected, connect them first
    // Else, make a call to the contract

    const wallet = await getBeaconInstance();

    const operations: any[] = [
      {
        kind: "transaction" as any,
        amount: bidAmount,
        destination: BUTTON_CONTRACT,
      },
    ];

    await wallet.client.requestOperation({
      operationDetails: operations,
    });
  };

  if (contractState && (bidAmount || 0) > 0) {
    return (
      <BidButtonInner onClick={handleBid} size="large" variant="contained">
        {`${new BigNumber(bidAmount || 0).shiftedBy(-6).toFixed()}`} ꜩ
        {!gameRunning ? " to start the next round" : ""}
      </BidButtonInner>
    );
  }

  return null;
};

export default BidButton;
