import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContractState } from "../dapp/tezos-service";
import { RootState } from "./store";

type ContractReduxState = {
  value: { state?: ContractState; balance?: string };
};

const initialState = { value: {} } as ContractReduxState;

export const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    setContractState: (
      state,
      action: PayloadAction<ContractReduxState["value"]["state"]>
    ) => {
      state.value.state = action.payload;
    },
    setContractBalance: (
      state,
      action: PayloadAction<ContractReduxState["value"]["balance"]>
    ) => {
      state.value.balance = action.payload;
    },
    clearContractState: (state) => {
      state.value = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { setContractState, setContractBalance, clearContractState } =
  contractSlice.actions;

export const selectContractState = (state: RootState) =>
  state.contract.value?.state;
export const selectContractBalance = (state: RootState) =>
  state.contract.value?.balance;

export default contractSlice.reducer;
