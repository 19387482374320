import { styled } from "@mui/material";
import Button from "@mui/material/Button";

export const WalletButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#0148FF",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#002FE6",
  },
}));

export const BidButtonInner = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#0148FF",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#002FE6",
  },
}));
