import React from "react";
import { withdrawBalance } from "../dapp/tezos-service";
import { selectContractState } from "../redux/contractSlice";
import { useAppSelector } from "../redux/hooks";
import { selectAddress } from "../redux/walletSlice";
import { BidButtonInner } from "./Buttons";

const WithdrawButton = ({ gameRunning }: { gameRunning: boolean }) => {
  const contractState = useAppSelector(selectContractState);
  const address = useAppSelector(selectAddress);

  const handleWithdraw = async () => {
    // If user is not connected, connect them first
    // Else, make a call to the contract
    console.log("make call to contract to withdraw");

    const res = await withdrawBalance();
    console.log("res: ", res);
  };

  if (gameRunning) {
    return null;
  }

  return (
    <BidButtonInner
      disabled={!!address && address !== contractState?.leader}
      onClick={handleWithdraw}
      size="large"
      variant="contained"
      style={{ marginTop: 28 }}
    >
      Withdraw
    </BidButtonInner>
  );
};

export default WithdrawButton;
