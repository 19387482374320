import React from "react";
import { WalletConnection } from "./wallet";

const Header = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 20,
      }}
    >
      <img
        alt="Battle button text logo with white button"
        src={"./logo.png"}
        style={{ width: 180 }}
      />
      <WalletConnection />
    </div>
  );
};

export default Header;
